import React, {useEffect, useState} from 'react';
import {graphql, Link} from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/molecules/SEO';
import PageHolder from '../components/organisms/PageHolder';
import Container from '../components/atoms/Container';
import {GatsbyImage} from 'gatsby-plugin-image';
import MarkdownConverter from '../components/atoms/MarkdownConverter';
import FeaturedProjects from "../components/organisms/FeaturedProjects";
import {useWindowSize} from "react-use";


const Holder = styled.div`
  small {
    margin-bottom: 1rem;
    @media ( ${props => props.theme.breakpoints.lg} ) {
      margin-bottom: 0.5rem;
    }
  }
`;

const ItemHolder = styled.div`
  border-top: 1px solid;
  transition: background-color 0.25s;
  cursor: pointer;
  background-color: transparent;

  .mobile-grid {
    display: grid;
    grid-template-columns: 1.5fr 0.8fr;
    column-gap: 0.5rem;
    align-items: center;
    @media ( ${props => props.theme.breakpoints.lg} ) {
      display: unset;
    }
  }

  &.noclickCursor {
    cursor: default;
    background-color: transparent;
  }

  @media ( ${props => props.theme.breakpoints.lg} ) {
    &:hover, &.noclickCursor:hover {
      background-color: ${props => props.theme.colours.orange};

      .inner-img {
        z-index: 1;
        opacity: 1;

        img {
          height: 100%;
        }
      }
    }
  }

  a {
    display: block;
  }

  &:last-child {
    border-bottom: 1px solid;
  }
`;

const ListGrid = styled.div`
  font-family: 'Eliza Mono', Helvetica, Arial, sans-serif;
  font-variant-numeric: slashed-zero;
  padding-left: 1rem;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    padding: 0;
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 2fr 1fr 1.5fr 1fr;
    grid-gap: 0.5rem;
    align-items: center;
  }

  small {
    ${props => props.theme.fluidType(-2.4)};
  }

  small:first-child {
    @media ( ${props => props.theme.breakpoints.lg} ) {
      padding-left: 1rem;
    }
  }

  p, h2 {
    margin: 0;
    padding: 0.5rem 0;
  }

  h2 {
    ${props => props.theme.fluidType(1.6)};
    font-family: 'ABCWhyteInktrap', Helvetica, Arial, sans-serif;
    @media ( ${props => props.theme.breakpoints.lg} ) {
      ${props => props.theme.fluidType(0.2)};
      padding: 0.5rem 1rem;
    }
  }

  p {
    font-family: 'Graebenbach', Helvetica, Arial, sans-serif;
    ${props => props.theme.fluidType(-1.4)};
    @media ( ${props => props.theme.breakpoints.lg} ) {
      ${props => props.theme.fluidType(-0.5)};
    }
  }

  //Controls the whether '&' is present
  span {
    &:after {
      content: ', ';
    }

    &:nth-last-child(2):after {
      content: ' & ';
    }

    &:last-child:after {
      content: ' ';
    }
  }

  .smaller {
    ${props => props.theme.fluidType(-2.4)};
    display: inline-block;
    padding-right: 1rem;
    margin: 0;
    @media ( ${props => props.theme.breakpoints.lg} ) {
      padding-right: 0;
      ${props => props.theme.fluidType(-1.4)};
    }
  }

  .removed {
    display: none;
    @media ( ${props => props.theme.breakpoints.lg} ) {
      display: block;
    }
  }
`;

const HeroText = styled.div`
  margin: 0 0 8rem 0;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    display: grid;
    grid-template-columns: 4fr 2fr;
    grid-gap: 1rem;
    margin: 4rem 0 6rem 0;
  }

  h1 {
    ${props => props.theme.fluidType(1.2)};
    @media ( ${props => props.theme.breakpoints.lg} ) {
      ${props => props.theme.fluidType(0.8)};
    }
  }
`;

const ImageHolder = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ImageInner = styled.div`
  height: inherit;

  .gatsby-image-wrapper {
    height: 100%;
    width: auto;
    @media ( ${props => props.theme.breakpoints.lg} ) {
      width: unset;
      height: unset;
    }
  }

  @media ( ${props => props.theme.breakpoints.lg} ) {
    width: 30%;
    position: absolute;
    top: -200px;
    left: 50%;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.25s;

    img {
      height: 0;
    }
  }

`;

const ConditionalLink = ({children, to, condition}) => (!condition && to)
    ? <Link to={to}>{children}</Link>
    : <>{children}</>;

function ProjectListPage({data}) {
    const size = useWindowSize()
    const [mobile, setMobile] = useState(null)

    useEffect(() => {
        if (size.width < 768) {
            setMobile(true)
        } else {
            setMobile(false)
        }
    }, [size.width, setMobile]);

    return (
        <PageHolder bg="cream">
            <SEO title="Work"/>
            <Container>
                <HeroText>
                    <h1>
                        <MarkdownConverter
                            content={data.contentfulGlobalSettings.projectPageHeading.projectPageHeading}/>
                    </h1>
                </HeroText>
                <FeaturedProjects/>
            </Container>
            <Holder>
                {mobile &&
                    <ListGrid>
                        <small>Projects:</small>
                    </ListGrid>
                }
                {!mobile &&
                    <ListGrid>
                        <small>Project</small>
                        <small>Services</small>
                        <small>Year</small>
                        <small>Client</small>
                        <small>Status</small>
                    </ListGrid>
                }
                {data.allContentfulProject.nodes.map(node => (
                    <ItemHolder key={node.id}
                                className={node.preventClickThrough ? 'project-item noclickCursor' : 'project-item'}>
                        <ConditionalLink to={`/project/${node.slug}`} condition={node.preventClickThrough}>
                            <div className="mobile-grid">
                                <ListGrid>
                                    <h2>{node.title}</h2>
                                    <p className="smaller removed">{node.services && node.services.map(service => <span
                                        key={service}>{service}</span>)}</p>
                                    <p className="smaller">{node.year}</p>
                                    <p className="smaller">{node.client}</p>
                                    <p className="smaller">{node.status}</p>
                                </ListGrid>
                                <ImageHolder>
                                    <ImageInner className="inner-img">
                                        <GatsbyImage
                                            alt={node.title}
                                            layout="fullWidth"
                                            image={node.featuredImage.gatsbyImageData}/>
                                    </ImageInner>
                                </ImageHolder>
                            </div>
                        </ConditionalLink>
                    </ItemHolder>
                ))}
            </Holder>
        </PageHolder>
    )
}

export default ProjectListPage;

export const projectListPageQuery = graphql`
    query projectListPageQuery($skip: Int!, $limit: Int!) {
        allContentfulProject(
            sort: {fields: year, order: DESC}
            limit: $limit
            skip: $skip
        ) {
            nodes {
                title
                preventClickThrough
                id
                slug
                services
                status
                year
                client
                featuredImage {
                    gatsbyImageData(
                        placeholder: BLURRED
                    )
                }
            }
        }
        contentfulGlobalSettings(title: {eq: "Global Settings"}) {
          projectPageHeading {
            projectPageHeading
          }
        }
    }
`;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';
import {GatsbyImage} from 'gatsby-plugin-image';
import MarkdownConverter from "../atoms/MarkdownConverter";

const Holder = styled.div`
  position: relative;
  margin-bottom: 4rem;
  color: ${props => props.theme.colours[props.colour]};
  @media ( ${props => props.theme.breakpoints.lg} ) {
    margin: 0;
  }

  a {
    display: block;
    position: relative;
  }
`;

const Text = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 10;
  text-align: center;

  h2 {
    ${props => props.theme.fluidType(4)};
    font-family: "ABCWhyteInktrap", Helvetica, Arial, sans-serif;
    @media ( ${props => props.theme.breakpoints.md} ) {
      ${props => props.theme.fluidType(3)};
    }
  }
`;

function CardTextOverlay({title, link, image, colour, description, altDescription}) {
    return (
        <Holder colour={colour}>
            <Link to={link}>
                {title && <Text>
                    <h2>{title}</h2>
                </Text>}
                <GatsbyImage
                    alt={title}
                    layout="fullWidth"
                    image={image.gatsbyImageData}/>
            </Link>
            {description && <MarkdownConverter content={description}/>}
            {altDescription && <div className="markdown"><p>{altDescription}</p></div>}
        </Holder>
    )
}

CardTextOverlay.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    colour: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
};

CardTextOverlay.defaultProps = {
    colour: 'yellow'
};

export default CardTextOverlay;

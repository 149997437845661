import React from 'react';
import {graphql, useStaticQuery} from "gatsby";
import CardTextOverlay from "../molecules/CardTextOverlay";
import WeirdImageGrid from "./WeirdImageGrid";

export default function FeaturedProjects() {
    const data = useStaticQuery(graphql`
    {
      allContentfulProject(limit: 4, filter: {preventClickThrough: {eq: false}, node_locale: {eq: "en-US"}}, sort: {order: DESC, fields: year}) {
        nodes {
          title
          preventClickThrough
          id
          slug
          services
          featuredImage {
            gatsbyImageData(placeholder: BLURRED)
          }
          excerpt {
            excerpt
          }
        }
      }
    }
  `)
    return (
        <WeirdImageGrid>
            {data.allContentfulProject.nodes.map((node, index) => {
                    return <CardTextOverlay key={index} image={node.featuredImage} link={`/project/` + node.slug}
                                            description={node.excerpt.excerpt} colour="orange"/>
                }
            )}
        </WeirdImageGrid>
    );
};
